import React from 'react';
import { FaBolt } from 'react-icons/fa';

import './Trends.css'

const Trends = () => {
  // Assuming you have an array of binary bots available for sale
  const flashsaleitems = [
    {
      id: 1,
      name: 'iPhone11',
      image: require('./img/iPhone11.png'),
      price: '$799',
      description: 'The ultimate iPad experience. Now with the M1 chip.',
    },
    {
      id: 2,
      name: 'iPhone13 Pro',
      image: require('./img/iPhone13Pro.png'),
      price: '$249',
      description: 'The sound. The fit. The active noise cancellation.',
    },
    {
      id: 3,
      name: 'iPad Air',
      image: require('./img/iPadAir.png'),
      price: '$399',
      description: 'The latest Apple Watch with a larger, always-on display and advanced health features.',
    },
    {
      id: 4,
      name: 'MacBook Air',
      image: require('./img/MacBookAir.png'),
      price: '$279',
      description: 'An affordable Apple Watch with a Retina display and essential health and fitness features.',
    },
    
  ];

  return (
    <div  className="flashSale">
      <h2 ><FaBolt className="flash-sale-icon" /> Flash Sale</h2>
      <div className="card-container">
        {flashsaleitems.map((item) => (
          <div key={item.id} className="card">
            <h3>{item.name}</h3>
            <img src={item.image} alt={item.name} />
            <p className='visible-paragraph' > {item.price}</p>
            <p className='hidden-paragraph'>{item.description}</p>
            
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default Trends;
