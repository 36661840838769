import React from 'react'
import { Link } from 'react-router-dom'
import './devices.css'

function DevicesEffect() {
  return (
    <div className='devices'>
        <section class="hotspots--wrapper">
            <img src="https://images.unsplash.com/photo-1494698853255-d0fa521abc6c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2600&q=80" alt="black Macbook near black iPhone 7 Plus and black Apple Watch" class="hotspots--figure"/>
            <Link class="hotspot hotspot--iphone" to="/">
                <span class="hotspot--title">iPhone 8 Plus</span>
                <span class="hotspot--cta"></span>
            </Link>
            <Link class="hotspot hotspot--macbook" to="/">
                <span class="hotspot--title">MacBook</span>
                <span class="hotspot--cta"></span>
            </Link>
            <Link class="hotspot hotspot--watch" to="/">
                <span class="hotspot--title hotspot--title__right">Apple Watch</span>
                <span class="hotspot--cta"></span>
            </Link>
        </section>
    </div>
  )
}

export default DevicesEffect
