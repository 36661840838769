import React, { useState} from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { FaShoppingBag } from 'react-icons/fa';
import { FaBars } from 'react-icons/fa';

function Navbar({ closeCart }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">

      <button className='menuBtn' onClick={toggleMenu}> <FaBars/> </button>
     

      <Link to="/" className="navbar__logo">
        <FaShoppingBag/> iShoppersHub
      </Link>

    

      <ul className={`navbar__links ${isMenuOpen ? 'open' : ''}`}>
        <li>
          <Link to="/products">Products</Link>
        </li>
        <li>
          <Link to="/aboutus">About Us</Link>
        </li>
        <li>
          <Link to="/products/cart">Checkout</Link>
        </li>
      </ul>

    </nav>
  );
}

export default Navbar;
