import React, { useState, useContext } from 'react';
  import './Products.css';
  import { Link } from 'react-router-dom';
  import { FaShoppingCart } from 'react-icons/fa';
  import SearchBar from '../SearchBar/SearchBar';
  import { CartContext } from '../../CartContext';
  
  function Products() {
    const { cart, addToCart } = useContext(CartContext);
    const [searchQuery, setSearchQuery] = useState('');
  
  
  const products = [
    {
      id: 1,
      name: 'iPhone 13 Pro',
      image: require('./productsimg/iPhone13Pro.png'),
      price: '$799',
      description: 'The ultimate iPad experience. Now with the M1 chip. 128GB',
    },
    {
      id: 2,
      name: 'AirPods Pro',
      image: require('./productsimg/AirPodsPro.png'),
      price: '$199',
      description: 'The sound. The fit. The active noise cancellation.',
    },
    {
      id: 3,
      name: 'Apple Watch Series 7',
      image: require('./productsimg/AppleWatchSerie7.png'),
      price: '$280',
      description: 'The latest Apple Watch with a larger, always-on display and advanced health features.',
    },
    {
      id: 4,
      name: 'Apple Watch SE',
      image: require('./productsimg/AppleWatchSE.jpeg'),
      price: '$195',
      description: 'An affordable Apple Watch with a Retina display and essential health and fitness features.',
    },
    {
      id: 5,
      name: 'iPhone 13',
      image: require('./productsimg/iPhone13Pro.webp'),
      price: '$570',
      description: 'The latest iPhone with a powerful A15 Bionic chip and impressive camera system.',
    },
    {
      id: 6,
      name: 'iPhone 12 Pro Max',
      image: require('./productsimg/iPhone12promax.png'),
      price: '$599',
      description: 'The larger-sized iPhone with advanced camera capabilities and a stunning Super Retina XDR display.',
    },
    {
      id: 7,
      name: 'iPhone SE (2nd Generation)',
      image: require('./productsimg/iPhoneSE.jpeg'),
      price: '$249',
      description: 'A compact yet powerful iPhone with the A13 Bionic chip and affordable price.',
    },
    {
      id: 8,
      name: 'iPhone 11',
      image: require('./productsimg/iPhone11.jpeg'),
      price: '$300',
      description: 'A popular iPhone model featuring a powerful processor and excellent camera performance.',
    },
    {
      id: 9,
      name: 'iPhone XR',
      image: require('./productsimg/iPhoneXR.jpeg'),
      price: '$239',
      description: 'A colorful iPhone with a Liquid Retina display and advanced Face ID technology.',
    },
    {
      id: 10,
      name: 'iPhone XS',
      image: require('./productsimg/iPhoneXS.jpeg'),
      price: '$269',
      description: 'An iPhone with a stunning OLED display and advanced dual-camera system.',
    },
    {
      id: 11,
      name: 'iPhone X',
      image: require('./productsimg/iPhoneX.jpeg'),
      price: '$199',
      description: 'A revolutionary iPhone with Face ID and a beautiful edge-to-edge display.',
    },
    {
      id: 12,
      name: 'iPhone 8 Plus',
      image: require('./productsimg/iPhone8Plus.jpeg'),
      price: '$159',
      description: 'A powerful iPhone with a larger display, advanced camera, and wireless charging.',
    },
    {
      id: 13,
      name: 'iPhone 8',
      image: require('./productsimg/iPhone8.jpg'),
      price: '$139',
      description: 'An iPhone with a sleek design, A11 Bionic chip, and improved camera technology.',
    },
    {
      id: 14,
      name: 'iPhone 7 Plus',
      image: require('./productsimg/IPhone7plus.jpeg'),
      price: '$125',
      description: 'A popular iPhone with a dual-camera system, water resistance, and stereo speakers.',
    },
    {
      id: 15,
      name: 'iPhone 7',
      image: require('./productsimg/iPhone7.jpeg'),
      price: '$117',
      description: 'An iPhone with a stylish design, A10 Fusion chip, and impressive battery life.',
    },
    {
      id: 16,
      name: 'iPad Pro 12.9" (5th Generation)',
      image: require('./productsimg/iPadPro12.png'),
      price: '$799',
      description: 'The most powerful iPad with a Liquid Retina XDR display and M1 chip for incredible performance.',
    },
    {
      id: 17,
      name: 'iPad Pro 11" (3rd Generation)',
      image: require('./productsimg/iPadPro113rd.png'),
      price: '$659',
      description: 'An ultra-portable iPad with ProMotion display and A12Z Bionic chip.',
    },
    {
      id: 18,
      name: 'iPad Air (4th Generation)',
      image: require('./productsimg/iPadAir.jpeg'),
      price: '$499',
      description: 'A powerful iPad with a sleek design and stunning Retina display.',
    },
    {
      id: 19,
      name: 'iPad (9th Generation)',
      image: require('./productsimg/iPad9th.jpeg'),
      price: '$329',
      description: 'The entry-level iPad with A13 Bionic chip and support for Apple Pencil.',
    },
    {
      id: 20,
      name: 'iPad mini (6th Generation)',
      image: require('./productsimg/iPad.png'),
      price: '$399',
      description: 'A compact iPad with a vibrant display, A15 Bionic chip, and Touch ID.',
    },
    {
      id: 21,
      name: 'iPad Pro 12.9" (4th Generation)',
      image: require('./productsimg/iPhone13Pro.webp'),
      price: '$599',
      description: 'An advanced iPad with ProMotion display, A12Z Bionic chip, and LiDAR scanner.',
    },
    {
      id: 22,
      name: 'iPad Pro 11" (2nd Generation)',
      image: require('./productsimg/iPadPro11.jpeg'),
      price: '$599',
      description: 'A versatile iPad with Face ID, Liquid Retina display, and A12Z Bionic chip.',
    },
    {
      id: 23,
      name: 'iPad Air (3rd Generation)',
      image: require('./productsimg/iPadPro113rd.png'),
      price: '$395',
      description: 'A lightweight and powerful iPad with A12 Bionic chip and support for Apple Pencil.',
    },
    {
      id: 24,
      name: 'iPad (8th Generation)',
      image: require('./productsimg/iPad8th.jpeg'),
      price: '$329',
      description: 'The affordable iPad with A12 Bionic chip, Touch ID, and all-day battery life.',
    },
    {
      id: 25,
      name: 'iPad mini (5th Generation)',
      image: require('./productsimg/iPadmini.jpeg'),
      price: '$399',
      description: 'A compact iPad with powerful performance, Retina display, and Apple Pencil support.',
    },
    {
      id: 26,
      name: 'MacBook Pro 16" (M1 Pro)',
      image: require('./productsimg/MacBookPro16.jpeg'),
      price: '$1799',
      description: 'The ultimate MacBook Pro with M1 Pro chip, stunning Retina display, and exceptional performance.',
    },
    {
      id: 27,
      name: 'MacBook Pro 14" (M1 Max)',
      image: require('./productsimg/MacBookPro14.jpeg'),
      price: '$1599',
      description: 'The powerful MacBook Pro with M1 Max chip, immersive display, and pro-level capabilities.',
    },
    {
      id: 28,
      name: 'MacBook Pro 13" (M1)',
      image: require('./productsimg/MacBookPro13.jpeg'),
      price: '$1199',
      description: 'The compact MacBook Pro with M1 chip, impressive performance, and incredible battery life.',
    },
    {
      id: 29,
      name: 'MacBook Air (M1)',
      image: require('./productsimg/MacBookAir.jpeg'),
      price: '$999',
      description: 'The thin and light MacBook Air with M1 chip, stunning Retina display, and all-day battery life.',
    },
    {
      id: 30,
      name: 'MacBook Pro 16" (Intel)',
      image: require('./productsimg/MacBookPro16Intel.jpeg'),
      price: '$2099',
      description: 'A powerful MacBook Pro with Intel Core processors, brilliant Retina display, and advanced graphics.',
    },
    {
      id: 31,
      name: 'MacBook Pro 13" (Intel)',
      image: require('./productsimg/MacBookPro13Intel.jpeg'),
      price: '$1099',
      description: 'A versatile MacBook Pro with Intel Core processors, stunning Retina display, and Touch Bar.',
    },
    {
      id: 32,
      name: 'MacBook Air (Intel)',
      image: require('./productsimg/MacBookAirIntel.jpeg'),
      price: '$799',
      description: 'The lightweight MacBook Air with Intel Core processors, long-lasting battery, and stunning design.',
    },
    {
      id: 33,
      name: 'MacBook 12"',
      image: require('./productsimg/Macbook12.jpeg'),
      price: '$1199',
      description: 'A compact MacBook with Retina display, reliable performance, and all-day battery life.',
    },
    {
      id: 34,
      name: 'MacBook Pro 15" (Retina)',
      image: require('./productsimg/MacBookPro15Retina.jpeg'),
      price: '$1999',
      description: 'A high-performance MacBook Pro with Retina display, powerful processors, and advanced graphics.',
    },
    {
      id: 35,
      name: 'MacBook Pro 13" (Retina)',
      image: require('./productsimg/MacBookPro13Retina.jpeg'),
      price: '$1099',
      description: 'A sleek MacBook Pro with Retina display, fast processors, and enhanced audio capabilities.',
    },
  ];

  
  
    const handleSearch = (query) => {
      setSearchQuery(query);
    };
  
    const filteredProducts = products.filter(
      (product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.description.toLowerCase().includes(searchQuery.toLowerCase())
    );
  
    return (
      <div className="products">
        <div className="cart-icon">
          <Link to="/products/cart">
            <FaShoppingCart className="carticon1" />
            {cart.length > 0 && <span className="cart-count">{cart.length}</span>}
          </Link>
        </div>
  
        <SearchBar onSearch={handleSearch} />
  
        {filteredProducts.length === 0 ? (
          <div className="no-products">
            <p>No products found. Inquire for more information.</p>
            <button
              className="inquire-button"
              onClick={() => window.open('https://wa.me/254787573052', '_blank')}
            >
              Inquire
            </button>
          </div>
        ) : (
          <div className="product-list">
            {filteredProducts.map((product) => (
              <div key={product.id} className="product-card">
                <div className="inner">
                  <span className="pricing">
                    <span>Activated</span>
                  </span>
                </div>
                {product.images ? (
                  product.images.map((image, index) => (
                    <img key={index} src={image} alt={product.name} />
                  ))
                ) : (
                  <img src={product.image} alt={product.name} />
                )}
                <h3>{product.name}</h3>
                <p>{product.description}</p>
                <div className="price">
                  <span>{product.price}</span>
                  <button onClick={() => addToCart(product)}>
                    <FaShoppingCart className="carticon1" /> Add To Cart
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
  
  export default Products;
  