import React from 'react';
import './Reviews.css'

const Reviews = () => {
  const reviews = [
    { name: 'John***', product: 'iPhone 12 Pro', rating: 5, statement: 'The camera quality is outstanding!' },
    { name: 'Lisa***', product: 'MacBook Pro', rating: 4, statement: 'Fast and reliable performance.' },
    { name: 'Mike***', product: 'iPad Air', rating: 5, statement: 'Great tablet for both work and entertainment.' },
    { name: 'Sarah***', product: 'Apple Watch Series 6', rating: 4, statement: 'Love the fitness tracking features.' },
    { name: 'Chris***', product: 'AirPods Pro', rating: 5, statement: 'Amazing sound quality and noise cancellation.' },
    { name: 'Emily***', product: 'iMac', rating: 4, statement: 'Sleek design and excellent display.' },
    { name: 'Alex***', product: 'iPad Pro', rating: 5, statement: 'A powerful tablet for creative professionals.' },
    { name: 'Jessica***', product: 'Apple TV 4K', rating: 4, statement: 'Immersive entertainment experience.' },
    { name: 'Ryan***', product: 'Mac Mini', rating: 5, statement: 'Compact yet powerful desktop computer.' },
    { name: 'Laura***', product: 'HomePod Mini', rating: 4, statement: 'Impressive audio quality in a small package.' }
  ];
  

  return (
    <div className='reviews'>
     
      {reviews.map((review, index) => (
        <div key={index} className="review">
          <h3>{review.name}</h3>
          
          <p>Product Purchased: {review.product}</p>
          
          <p><br/>{review.statement}</p>
          
          <div className="rating">
            {[...Array(review.rating)].map((_, index) => (
              <span key={index}>&#9733;</span>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Reviews;
