import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import CartPage from './components/ShoppingCart/CartPage';
import Navbar from './components/Navbar/NavBar';
import Trends from './components/Trends/Trends';
import Convenience from './components/Convenience/Convenience';
import AboutUs from './components/AboutUs/AboutUs';
import FAQ from './components/FAQ/FAQ';
import Footer from './components/Footer/Footer';
import Copyright from './components/Copyright/Copyright';
import Productsv1 from './components/ShoppingCart/Productsv1';
import Banner from './components/Banner/Banner';
import Procedure from './components/CssEffects/procedure';
import Checkout from './components/Checkout/Checkout';
import Reviews from './components/Reviews/Reviews';
import CartnCheckout from './components/CartnCheckout/CartnCheckout';
import ContactUs from './components/ContactUs/ContactUs';
import { CartProvider } from './CartContext';
import DevicesEffect from './components/CssEffects/DevicesEffect';

function App() {
  const [cart, setCart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);

  const closeCart = () => setIsCartOpen(false);

  const addToCart = (product) => {
    setCart([...cart, product]);
  };

  return (
    <Router>
      <CartProvider> 
      <div className="App">
        {isCartOpen && <CartPage cart={cart} closeCart={closeCart} />}
        <Navbar closeCart={closeCart} />

        <Routes>
          <Route
            path="/"
            element={
              <>
                <Banner />
                <Procedure />
                <DevicesEffect/>
                <Trends />
                <AboutUs />
              </>
            }
          />
          <Route
            path="/products"
            element={
              <>
                
                <Productsv1 addToCart={addToCart} />
                <Reviews />
              </>
            }
          />
          <Route
            path="/products/cart"
            element={<CartnCheckout cart={cart} closeCart={closeCart} />}
          />
          <Route path="/aboutus" element={<> <AboutUs /> <Reviews /></>} />
          <Route path="/checkout" element={<Checkout />} />
        </Routes>
        <ContactUs/>
        <Convenience />
        <FAQ />
        <Footer />
        <Copyright />
      </div>
      </CartProvider>
    </Router>
  );
}

export default App;
