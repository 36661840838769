import React, { useState } from 'react';
import './SearchBar.css'
import { FaSearch } from 'react-icons/fa';

function SearchBar({ onSearch }) {
  const [searchQuery, setSearchQuery] = useState('');

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch(searchQuery);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" value={searchQuery} onChange={handleChange} placeholder= " Search..." />
      <button type="submit"><FaSearch/> </button>
    </form>
  );
}

export default SearchBar;
