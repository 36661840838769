import React from 'react';
import { Link } from 'react-router-dom';
import './ContactUs.css';
import whatsappicon from './icons/whatsappicon.png';
import instaicon from './icons/instaicon.png';
import gmailicon from './icons/gmailicon.png';
function ContactUs() {
  return (
    <div className="contactUs">
      <h1>Contact Us</h1>
      <div className="contactLinks">
        <div className='linkSection'>
          <img src={whatsappicon}  className='icons' alt="whatsappicon" />
        <Link to="https://wa.me/254787573052" target="_blank" rel="noopener noreferrer">
          <i className="fa fa-whatsapp"></i> WhatsApp
        </Link>
        </div>
        <div className='linkSection'>
        <img src={instaicon}  className='icons' alt="instaicon" />

        <Link to="https://www.instagram.com/iphonestoreeastafrica/" target="_blank" rel="noopener noreferrer">
          <i className="fa fa-instagram"></i> Instagram
        </Link>
        </div>
        <div className='linkSection'>
        <img src={gmailicon}  className='icons' alt="gmailicon" />
        <a href="mailto:ishoppershub@protonmail.com">
          <i className="fa fa-envelope"></i> Email
        </a>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
