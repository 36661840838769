import React from 'react'
import './Copyright.css'

function Copyright() {
  return (
    <footer>
    <p> Copyright 2023 iShoppersHubEA, All rights reserved. </p>

  
    
  </footer>
  )
}

export default Copyright
