import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer>
      <ul>
        <li>MALL</li>
        <li>Travel</li>
        <li>SELLER</li>
        <li>MOBILE APP</li>
      </ul>

      <div>
        <p>Contact Us</p>
        <ul>
          <li>Email: ishoppershub@protonmail.com</li>
          <li>Phone: +1 213-377-5942</li>
          <li>Address: 503 T. 3rd St, Ste. 165, Carlifornia, USA</li>
        </ul>
      </div>

      <div>
        <p>Follow Us</p>
        <ul>
          <li>Facebook</li>
          <li>Twitter</li>
          <li>Instagram</li>
        </ul>
      </div>

      <div>
        <p>Terms of Service</p>
        <ul>
          <li>Privacy Policy</li>
          <li>Refund Policy</li>
          <li>Shipping Policy</li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
