import React from 'react'
import './FAQ.css'

function FAQ() {
  return (
    <div className="frequentAQ">
              <h1>Frequently Asked Questions</h1>

      <div className="container">
        
        
        <details>
          <summary>What payment methods do you accept?</summary>
          <div>We accept credit/debit cards, PayPal, and Apple Pay.</div>
        </details>
        
        <details>
          <summary>How long does shipping take?</summary>
          <div>Shipping times vary depending on the destination. Typically, it takes 3-7 business days for domestic orders.</div>
        </details>
        
        <details>
          <summary>What is your return policy?</summary>
          <div>We have a 30-day return policy. You can return the product in its original condition within 30 days of purchase for a refund or exchange.</div>
        </details>
        
        <details>
          <summary>Can I track my order?</summary>
          <div>Yes, once your order is shipped, you will receive a tracking number via email.</div>
        </details>
        
        <details>
          <summary>How can I contact customer support?</summary>
          <div>You can contact our customer support team through email, phone, or live chat. Visit the "Contact Us" page for more information.</div>
        </details>
        
        <details>
          <summary>Do you offer international shipping?</summary>
          <div>Yes, we offer international shipping to many countries. Shipping rates and times may vary based on the destination.</div>
        </details>
        </div>
        <details>
          <summary>Are there any discounts or promotions available?</summary>
          <div>We occasionally offer discounts and promotions. Stay updated by subscribing to our newsletter or following our social media channels.</div>
        </details>
        
      
    </div>
  )
}

export default FAQ
