import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import emptycart from './emptycart.png';
import Select from 'react-select';
import { Country, City } from 'country-state-city';

import './CartnCheckout.css';
import { FaArrowRight, FaTrash } from 'react-icons/fa';

import { CartContext } from '../../CartContext';

const CartnCheckout = () => {
  const { cart, removeCartItem } = useContext(CartContext); // Access cart from CartContext

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState('');

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
};


  useEffect(() => {
    // Fetch country options on component mount
    const countries = Country.getAllCountries().map((country) => ({
      value: country.isoCode,
      label: country.name
    }));
    setCountryOptions(countries);
  }, []);

  useEffect(() => {
    // Fetch city options when selectedCountry changes
    if (selectedCountry) {
      const cities = City.getCitiesOfCountry(selectedCountry.value).map((city) => ({
        value: city.name,
        label: city.name
      }));
      setCityOptions(cities);
    } else {
      setCityOptions([]);
    }
  }, [selectedCountry]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedCity(null);
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
  };

  const subtotal = cart.reduce(
    (total, item) => total + parseInt(item.price.slice(1)) * item.quantity,
    0
  );
  const tax = subtotal * 0.01;
  const total = subtotal + tax;

  const handleQuantityChange = (item, quantity) => {
    // Update cart quantity using CartContext
  };

  const handleRemoveItemClick = (item) => {
    // Filter out the item from the cart
    //const updatedCart = cart.filter((cartItem) => cartItem.id !== item.id);

    // Call the removeCartItem function from CartContext to remove the item from the cart
    removeCartItem(item);
  };

  const handlePaidClick = () => {
    window.location.href = 'https://wa.me/254774575419';
  };

  return (
    <div className="cartncheckout">
      <div className="cart-column">
        <h2>Cart</h2>
        
        {cart.length === 0 ? (
          <div className='emptycart'>
          <img src={emptycart} alt='emptycart' />
          </div>
        ) : (
          cart.map((item) => (
            <div className="card-container" key={item.id}>
              <div className="cart-item">
                <div className="product-image">
                  <img src={item.image} alt={item.name} />
                </div>
                <div className="product-details">
                  <h3>{item.name}</h3>
                  <p className='visible-paragraph' >{item.price}</p>
                  <p className='hidden-paragraph'> {item.description}</p> 
                  <div className="quantity">
                    <button onClick={() => handleQuantityChange(item, item.quantity - 1)}>-</button>
                    <span>{item.quantity}</span>
                    <button onClick={() => handleQuantityChange(item, item.quantity + 1)}>+</button>
                  </div>
                </div>
                <div className="product-total">
                  <p>Total: ${parseInt(item.price.slice(1)) * item.quantity}</p>
                  <button onClick={() => handleRemoveItemClick(item)} className='hidden-paragraph'><FaTrash/></button>
                </div>
              </div>
            </div>
          ))
        )}
        {cart.length > 0 && (
          <React.Fragment>
          <div className="payoutDiv">

            <div className="subtotal">
              <span>Subtotal:</span>
              <span>USD{subtotal.toFixed(2)}</span>
            </div>
            <div className="tax">
              <span>Tax:</span>
              <span>USD{tax.toFixed(2)}</span>
            </div>
            <div className="total">
              <span>Total:</span>
              <span>USD{total.toFixed(2)}</span>
            </div>
            </div>

          </React.Fragment>
        )}
        <div className='continueShopping'>
          <Link to="/products">Continue Shopping <FaArrowRight/></Link>
        </div>
      </div>

      <div className="checkout-column">
        <h2>Checkout</h2>
        {cart.length === 0 ? (
          <p>Your cart is empty. Add items to proceed to checkout.</p>
        ) : (
          <React.Fragment>
            <form className='checkoutForm'>
                  <div class="form-row">
                      <div class="input-data">
                        <input type="text" required/>
                        <div class="underline"></div>
                        <label for="">First Name</label>
                      </div>
                      <div class="input-data">
                        <input type="text" required/>
                        <div class="underline"></div>
                        <label for="">Last Name</label>
                      </div>
                  </div>
                    <div class="form-row">
                        <div class="input-data">
                          <input type="text" required/>
                          <div class="underline"></div>
                          <label for="">Email Address</label>
                        </div>
                        <div class="input-data">
                          <input type="text" required/>
                          <div class="underline"></div>
                          <label for="">Phone Number</label>
                    </div>
                  </div>
                  

              <div className="form-field">
                <label htmlFor="country">Country:</label>
                <Select
                  id="country"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  options={countryOptions}
                  placeholder="Select a country"
                  className='selection'
                />
              </div>
              <div className="form-field">
                <label htmlFor="city">City:</label>
                <Select
                  id="city"
                  value={selectedCity}
                  onChange={handleCityChange}
                  options={cityOptions}
                  placeholder="Select a city"
                  isDisabled={!selectedCountry}
                  className='selection'
                />
              </div>
              
              <div className="form-field">
              <label htmlFor="payment">Payment Method:</label>
              <div className='note'><p >NOTE: Convert The Total Amount To Your Local Currency</p></div>
              
              <select id="payment" name="payment" required onChange={handlePaymentChange} className='paymentSelection'>
                <option value="">Select Payment Method</option>
                <option value="Binance">Binance Pay</option>

                <option value="MTN Rwanda">Mobile Money Rwanda</option>
                <option value="Vodacom Tanzania">MTN TANZANIA</option>
              </select>
            </div>
              {/* Add other checkout form fields */}
              {selectedPayment === 'Binance' && (
                <div className='paymentChoice'>
                    <h3>Binance Pay</h3>
                    <p>Pay ID</p>
                    <p>303085474</p> 
                    
                </div>    
                )}
              {selectedPayment === 'MTN Rwanda' && (
                 <div className='paymentChoice'>
                    <h3>MTN Rwanda</h3>
                    <p>Payment To Kenyan Agent</p>
                    <p>dial *830#  </p> 
                    <p>send money to Kenya from your MTN line</p>
                    <p>254714271503</p>
                </div>    
                )}

                {selectedPayment === 'Vodacom Tanzania' && (
                   <div className='paymentChoice'>
                    <h3>Vodacom Tanzania</h3>
                    <p>Payment To Kenyan Agent</p>
                    <p>dial *150*00#</p> 
                    <p>Send money to MPESA Kenya” on your Vodacom line</p>
                    <p>254714271503</p>
                </div>
                  
                )}

            </form>

            <div className='paidButton'>
            <button onClick={handlePaidClick} className="button-17">Paid</button>
            </div>
            
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default CartnCheckout;
