import React, { useState, useEffect } from 'react';
import './Checkout.css'
import Select from 'react-select';
import { Country, City } from 'country-state-city';



const Form = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState('');

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  useEffect(() => {
    // Fetch country options on component mount
    const countries = Country.getAllCountries().map((country) => ({
      value: country.isoCode,
      label: country.name
    }));
    setCountryOptions(countries);
  }, []);

  useEffect(() => {
    // Fetch city options when selectedCountry changes
    if (selectedCountry) {
      const cities = City.getCitiesOfCountry(selectedCountry.value).map((city) => ({
        value: city.name,
        label: city.name
      }));
      setCityOptions(cities);
    } else {
      setCityOptions([]);
    }
  }, [selectedCountry]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedCity(null);
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
  };

  const handlePaymentChange = (event) => {
    setSelectedPayment(event.target.value);
};

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    // You can access the form field values using the state variables (firstName, lastName, email, address)
  };

  return (
    <div className="Checkout">
    <form onSubmit={handleSubmit}className='checkoutForm'>
      <div className="Details">
      <div>
        <label htmlFor="firstName">First Name:</label>
        <input
          type="text"
          id="firstName"
          value={firstName}
          onChange={handleFirstNameChange}
        />
      </div>
      <div>
        <label htmlFor="lastName">Last Name:</label>
        <input
          type="text"
          id="lastName"
          value={lastName}
          onChange={handleLastNameChange}
        />
      </div>
      </div>
      <div className="Details">
      <div>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
        />
      </div>
      <div>
        <label htmlFor="address">Address:</label>
        <textarea
          id="address"
          value={address}
          onChange={handleAddressChange}
        ></textarea>
      </div>
      </div>
      <div className='Location'>
             <div className='selection'>
                <label htmlFor="country">Country:</label>
                <Select
                  id="country"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  options={countryOptions}
                  placeholder="Select a country"
                  
                />
              </div>
              <div className="form-field">
                <label htmlFor="city">City:</label>
                <Select
                  id="city"
                  value={selectedCity}
                  onChange={handleCityChange}
                  options={cityOptions}
                  placeholder="Select a city"
                  isDisabled={!selectedCountry}
                  className='selection'
                />
              </div>
      </div>

      <div className="form-field">
              <label htmlFor="payment">Payment Method:</label>
              <select id="payment" name="payment" required onChange={handlePaymentChange}>
                <option value="">Select Payment Method</option>
                <option value="creditCard">Credit Card</option>
                <option value="paypal">PayPal</option>
              </select>
            </div>
              {/* Add other checkout form fields */}
              {selectedPayment === 'creditCard' && (
                <div>
                  {/* Display credit card payment procedure */}
                  <h3>Credit Card Payment Procedure</h3>
                  {/* Include relevant input fields and instructions for credit card payment */}
                </div>
                )}

                {selectedPayment === 'paypal' && (
                  <div>
                    {/* Display PayPal payment procedure */}
                    <h3>PayPal Payment Procedure</h3>
                    {/* Include relevant input fields and instructions for PayPal payment */}
                  </div>
                )}


      <button type="submit">Submit</button>
    </form>
    </div>
  );
};

export default Form;
