import React from 'react';
import './AboutUs.css';
import deliveryBoy from './img/3Ddeliveryboy.png';  
import shoponTheGo from './img/shoponthego.png';  

function AboutUs() {
  return (
    
    <div className="about-us">
      <div className="text-column">
        <h2>iShoppersHubEA: Your One-Stop Shop for iProducts in East Africa</h2>
        <p>
          Welcome to iShoppersHubEA, your one-stop-shop for brand new iPhones in East Africa. At iShoppersHubEA, we are
          passionate about providing our customers with the latest Apple technology, backed by our commitment to quality
          and customer service.
        </p>
        <p>
          Our mission is to make the latest iPhones accessible to everyone in East Africa. We achieve this by providing
          fast and reliable shipping, with most orders arriving within 4 days of purchase. We also offer upfront payment
          options to give our customers peace of mind and a hassle-free shopping experience.
        </p>
        <p>
          At iShoppersHubEA, we pride ourselves on our commitment to customer satisfaction. Our Buyer Protection program
          covers your purchase from click to delivery, ensuring that you receive your iPhone in perfect condition. We
          also offer round-the-clock support to assist with any questions or concerns you may have.
        </p>
      </div>
      
      <div className="iphone-column">
          <div className="iphone-x">
          
          <img className="deliveryGuy" src={deliveryBoy} alt="delivery" />
          <img className="shoponTheGo" src={shoponTheGo} alt="Shop On The Go" />
          </div>
      </div>
    </div>
  );
}

export default AboutUs;
